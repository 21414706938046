import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LayoutRoutes from './Routes/LayoutRoutes';
import Whatsapp from './componetns/whats app/Whatsapp';

function App() {
  return (
   <>
  <LayoutRoutes/>
  
   </>
  );
}

export default App;
