import React from "react";
import './Loader.css'
const Loader = () => {
    
  return (
    <div class="loaderRectangle">
<div></div>
<div></div>
<div></div>
<div></div>
<div></div>
</div>
    
  );
};

export default Loader;
